<template>
  <div class="card-header text-end">
    <img src="@/assets/bg.jpg" class="image-bg" alt="image" />
    <div>
      <i class="bi bi-gear icon"></i>
    </div>
    <div class="row header-logo-text mt-3">
      <div class="col-5" style="padding: 0">
        <div class="text-center"></div>
      </div>
      <div class="col-7 text-start" style="padding: 0">
        <div class="row"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, inject } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
export default defineComponent({
  name: "Header",
  setup() {
    const store = inject("store");
    const router = inject("router");
    const Profire = store.getters.getProfire;

    const edit = () => {
      store.dispatch(SET_REGISTER, Profire);
      router.push({ name: "Edit" });
    };

    return {
      edit,
    };
  },
});
</script>

<style scoped>
.card-header {
  position: relative;
  height: 160px;
  min-height: 160px;
  overflow: hidden;
  border-bottom: none !important;
  padding: 0 !important;
}
.image-bg {
  width: 100%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: white;
}
.header-logo-text {
  color: white;
  position: absolute;
  top: 42px;
  width: 100%;
}

.img-profile {
  width: 80px;
  border-radius: 50%;
  border: 2px solid rgb(255, 255, 255);
  background-color: white;
}

.title-home {
  font-size: 20px;
  font-weight: bold;
  background-color: #1f904963;
  color: whitesmoke;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
</style>
